import "../../node_modules/mmenu-light/dist/mmenu-light"

jQuery(document).ready(function ($) {
	//menu center logo
	const menuLogo = $(".menu-logo")
	const targetCount = $(".menu-center-logo ul li").length
	const target = $(`.menu-center-logo ul li:nth-child(${targetCount / 2})`)
	menuLogo.insertAfter(target)

	menuLogo.wrap(`<a href="${homeUrl}"></a>`)

	//hamburger
	const menu = new MmenuLight(document.querySelector("#menu"))
	const navigator = menu.navigation()
	const drawer = menu.offcanvas({
		// position: "right",
	})
	$("#toggle").click(function (e) {
		e.preventDefault()
		drawer.open()
		$(this).addClass("active")
	})
	$(".mm-ocd__backdrop").click(function () {
		drawer.close()
		$("#toggle").removeClass("active")
	})
	$(".mm-ocd__content").prepend('<div class="close"></div>')
	$(".mm-ocd__content .close").click(function () {
		drawer.close()
		$("#toggle").removeClass("active")
	})
})
